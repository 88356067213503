<template>

  <PDialog 
    @close="onCancel" 
    :title="$tk('CreateDialog.NewReceiverRegistration')" 
    classes="left-0 md:left-auto md:w-3/5 lg:w-2/5"
  >

    <PDialogContent class="relative">
  
      <PInput 
        :label="$tk('Common.General.Reference')" 
        :focus="true"
        v-model="receiversRef" 
        :disabled="isCreating"
      />

      <PLocationPicker 
        class="mt-4" 
        label="Fra" 
        @select="onLocationFromSelect"        
        :placeholder="$tk('CreateDialog.SelectLocation')"         
        :value="locationIdFrom"
        :pooling="!customer.palletReceiptDays"
        :palletReceipts="customer.hasPalletReceiptDays"                
        :disabled="isCreating"
        :query="locationsQuery" 
        :exclude="[location.id]"
        :excludeSr="true"
      />

      <div 
        class="mt-4 mb-1 text-sm" 
        v-if="locationIdFrom"
        v-html="$tk('CreateDialog.ProductsAndQuantitiesReceived')"
      ></div>

      <template v-if="transactions.length > 0">
        <div class="px-4 py-2 mb-4 bg-gray-200 rounded shadow-inner">
          <table class="w-full">
            <tr v-for="(transaction, index) in transactions" :key="index">
              <td class="py-2">
                <div class="flex flex-col items-center md:flex-row">                  
                  <img :src="`${assetsBaseUrl}/media.ashx?${transaction.productImage}&template=product-image-xsmall`" />                    
                  <div class="text-center md:ml-2 md:text-left">
                    <div class="font-medium">{{ transaction.productName }}</div>
                    <template v-if="customer.hasPalletReceiptDays">
                      <div class="text-sm">{{ transaction.qualityName }}, {{ transaction.treatmentName }}</div>
                    </template>
                  </div>
                </div>
              </td>
              <td class="w-20 py-2">
                <PNumber 
                  v-model="transaction.quantityConfirmed" 
                  :disabled="isCreating"
                  :min="minQuantity"
                  :max="maxQuantity"
                />
                </td>
              <td class="w-20 py-2 text-right" v-if="!singleProduct">
                <PButton 
                  color="secondary" 
                  @click="onRemove(index)"                   
                  :disabled="isCreating"                  
                >
                  <span v-html="$tk('Common.Actions.Delete')"></span>
                </PButton>                
              </td>
            </tr>
          </table>
        </div>
      </template>

      <div v-show="!singleProduct" class="flex flex-col md:flex-row md:items-center">
        <PProductPicker 
          class="flex-1" 
          :grouped="!customer.hasPalletReceiptDays"          
          :pooling="!customer.hasPalletReceiptDays"
          :palletReceipts="customer.hasPalletReceiptDays"
          :exclude="transactions" 
          :locationId="locationIdFrom"
          :disabled="!locationIdFrom || isCreating" 
          :value="selectedProductKey"
          @select="onProductSelect"
          @loaded="onProductPickerLoaded"
        />

        <div class="flex items-center mt-2 md:ml-2 md:mt-0">
        
          <PNumber 
            class="w-20 flex-0" 
            v-model="quantity" 
            :disabled="!locationIdFrom || isCreating" 
            :min="minQuantity" 
            :max="maxQuantity"           
          />

          <PButton 
            class="ml-2 flex-0" 
            @click="onAdd" 
            color="secondary" 
            :disabled="!canAdd || isCreating"          
          >
            <span v-html="$tk('Common.Actions.Add')"></span>
          </PButton>

        </div>

      </div>
      
    </PDialogContent>
    <PDialog-actions>
      <div class="flex justify-between" :class="{ 'w-full': error }">

        <div v-if="error" class="flex items-center px-4 text-sm font-medium text-red-700 bg-red-100 border border-red-300 rounded-lg">
          <div v-html="error"></div>
        </div>

        <div>

          <PButton 
            @click="onCreate" 
            :disabled="!canCreate" 
            :loading="isCreating"            
          >
            <span v-html="$tk('Common.Actions.Create')"></span>
          </PButton>

          <PButton 
            @click="onCancel" 
            :disabled="isCreating" 
            color="secondary" 
            class="ml-2"            
          >
            <span v-html="$tk('Common.Actions.Cancel')"></span>
          </PButton>

        </div>
      </div>
    </PDialog-actions>
  </PDialog>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { find, get, sumBy } from "lodash"
import { format } from 'date-fns'
import { transactionStatus } from "../../../enums"

export default {
  
  props: ["value"],
  
  data () {
    return {
      visible: false,
      receiversRef: "",
      locationIdFrom: "",
      locationFrom: null,      
      transactions: [],
      products: [],
      locations: [],
      quantity: 1,
      product: null,
      isCreating: false,
      singleProduct: true,
      error: ""      
    }
  },

  computed: {

    ...mapGetters(["location", "customer", "transactionTypes"]),

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    locationsQuery () {
      return `?poolingGroupId=${this.location.poolingGroupId}&all=true`
    },

    canAdd () {
      return this.product !== null && this.quantity > 0
    },

    canCreate () {
      const totalQuantity = sumBy(this.transactions, "quantityConfirmed")      
      return this.locationIdFrom !== "" && totalQuantity > 0
    },

    transactionTypeInternalCode () {
      // poolingprodukt og forskjellig kundenummer = gl, alt annet er oo
      const isDifferentCustomer = this.customer.id !== get(this.locationFrom, "customerId", "") 
      return isDifferentCustomer ? "GL" : "OO"
    },

    transactionType () {
      return find(this.transactionTypes, transactionType => 
        transactionType.internalCode === this.transactionTypeInternalCode)
    },

    maxQuantity () {      
      return get(this.transactionType, "maxQuantity", 0)
    },

    minQuantity () {      
      return get(this.transactionType, "minQuantity", 0)
    },

    step () {
      return get(this.product, "quantityPick", 1)
    },

    selectedProductKey () {
      return get(this.product, "key", "")
    }

  },

  methods: { 

    onLocationFromSelect (location) {    
      this.transactions = []
      this.locationFrom = location
      this.locationIdFrom = location.id
    },

    onProductSelect (product) {        
      this.product = product
    },

    onProductPickerLoaded (products) {
      this.singleProduct = products.length === 1
      if (this.singleProduct) {
        this.product = products[0]
        this.onAdd()
      } else {
        this.addRowVisible = true
      }
    },

    async onCreate () {
          
      this.error = ""
      this.isCreating = true 

      try {

        const order = await http.post("order", {
          dateSend: format(new Date(), "yyyy-MM-dd") ,
          transactionType: this.transactionTypeInternalCode,
          receiversRef: this.receiversRef,
          locationIdFrom: this.locationIdFrom,
          locationIdTo: this.location.id,
          transactions: this.transactions,
          commit: true
        })

        // The Order API method does not take TransactionStatus 
        // argument and must be updated with the OrderStatus API method.        

        await http.post("OrderStatus", {
          id: order.id,
          transactionStatus: this.customer.hasPalletReceiptDays > 0 
            ? transactionStatus.CONFIRMED 
            : transactionStatus.NOTAGREED
        })

        this.isCreating = false 
        this.$emit("close")                            

      
      } catch(e) {
        
        this.isCreating = false 
        this.error = e.reason

      }

    },
    onAdd () {
      
      this.transactions.push({
        productId: this.product.productId,
        productImage: this.product.image,
        productName: this.product.productName,
        isPooling: this.product.isPooling,
        treatmentId: this.product.treatmentId,
        treatmentName: this.product.treatmentName,
        qualityId: this.product.qualityId,
        qualityName: this.product.qualityName,        
        quantityOrdered: 0,
        quantityPicked: 0,
        quantityConfirmed: parseInt(this.quantity, 10),
      })

      this.product = null      
      this.quantity = 0

    },

    onRemove (index) {
      this.transactions.splice(index, 1)
    },

    onCancel () {
      this.$emit("close")
    }

  }
}
</script>